import React from 'react';

function DropdownMenu({ isOpen }) {
  return (
    <ul className={`menu mt-1.5 ${isOpen ? 'active' : ''}`} style={{ zIndex: 100, position: 'absolute' }}>
      <li className="py-1.5"><a href="https://www.marketplaceapp.lumos-health.com">Get Started</a></li>
      <li className="py-1.5"><a href="https://www.lumos-health.com/about">About</a></li>
      <li className="py-1.5 font-bold"><a href="https://www.blog.lumos-health.com">Blog</a></li>
    </ul>
  );
}

export default DropdownMenu;