import React from 'react';
import { Link } from 'react-router-dom';
import blogsData from '../data/blogsData'; // You'll need to load your blog data here

function BlogList() {
  return (
    <div id="big-blog">
      Blog
    <div className="mt-10">
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-1 xl:grid-cols-1 gap-10">
        <div className="flex-container">
          {blogsData.map((blog, index) => (
              <div className="blog-summary-container">
                <Link to={`/blog/${blog.slug}`}>
                  <div className="blog-summary-elements">
                    <img src={blog.image} alt={blog.title} className="blog-summary-image" />
                    <div className="blog-summary-text-elements">
                      <h2 className="blog-summary-title">{blog.title}</h2>
                      <p className="blog-summary-body">{blog.summary}</p>
                      <div className="flex flex-row justify-between">
                        <p className="blog-summary-readtime">{blog.length}</p>
                    </div>
                  </div>
                  </div>
                </Link>
              </div>
          ))}
        </div>
      </div>
    </div>
    </div>
  );
}

export default BlogList;