const blogsData = [
    // Add more blog posts as needed. Make sure to add newer blog posts at the top of the sequence
    {
      title: "What's the deal with ACA subsidies?",
      image: './images/blog6_subsidies_aca.png',
      length: '7 Minute Read',
      summary: 'In this post, we will discuss the details of ACA subsidies: why they were instituted, how they have expanded, how they are calculated and who qualifies',
      contentPath: 'blog6.html',
      slug: "subsidies-aca"
    },
    {
      title: "Is a colonoscopy fully-covered under an ACA plan?",
      image: './images/blog5_colonoscopy_aca.png',
      length: '7 Minute Read',
      summary: 'Understanding the costs of colonoscopies under ACA plans is crucial for effective healthcare management. This post delves into the differences between diagnostic and preventative colonoscopies, covering integral services, insurance coverage nuances, and tips to avoid unexpected expenses',
      contentPath: 'blog5.html',
      slug: "colonoscopy-aca"
    },
    {
      title: "How much is an HSA worth?",
      image: './images/blog4_piggy_bank.jpg',
      length: '10 Minute Read',
      summary: 'HSA plans can save you a lot of money. In this post, we cover the finer points of an HSA, how you can make the most out of it, and how much they are ultimately worth.',
      contentPath: 'blog4.html',
      slug: "hsa-worth"
    },
  {
    title: "How will my medical costs increase with age?",
    image: './images/blog3_medical_costs_summary.jpg',
    length: '15 Minute Read',
    summary: 'This post will overview how you should expect medical costs to change as you age, so you can be financially prepared to manage rising medical costs as you age.',
    contentPath: 'blog3.html',
    slug: "medical-costs-age"
  },
  {
    title: "How To Get Health Insurance If I've Been Laid Off?",
    image: './images/how_to_health_image.jpg',
    length: '8 Minute Read',
    summary: 'Being laid off and losing health insurance can be a stressful. Read our post to understand your options and make sure you and your family are covered.',
    contentPath: 'blog2.html',
    slug: "health-insurance-laid-off"
  },
  {
    title: 'How Does Lumos Calculate the True Cost of Each Plan?',
    image: './images/blog1_calculator.jpg',
    length: '5 Minute Read',
    summary: 'Lumos uses real individual healthcare usage data from thousands of people to accurately estimate your expected all-in cost.',
    contentPath: 'blog1.html',
    slug: "lumos-true-cost"
  }
];

export default blogsData;
